<template>
    <v-container class="text-left">
        <div v-if="!loading && !isEmpty(article)">
            <v-row justify="center" class="pt-12">
                <h3 class="primary--text">{{ article[`title_${locale.split('-')[0]}`] }}</h3>
            </v-row>
            <v-row class="pl-4">{{'date' | localize }}: {{ dateLocale(article.date) }}</v-row>
            <v-row>
                <v-col v-if="article.image" cols="12" md="6">
                    <v-img class="mt-2 rounded" 
                        :src="article.image"
                        :lazy-src="article.image"
                        aspect-ratio="3/2">
                        <template v-slot:placeholder>
                            <v-row class="fill-height ma-0" justify="center">
                                <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </v-col>
                <v-col class="text-justify">
                {{ article[`text_${locale.split('-')[0]}`] }}
                </v-col>
            </v-row>
            <v-row justify="end">
                <v-btn class="ml-auto my-4 text-lowercase" color="primary" outlined :to="{path: '/', hash: 'blog'}">
                    {{'go back' | localize }}
                </v-btn>
            </v-row>
        </div>
        <div v-else>
            <loader></loader>
        </div>
    </v-container>
</template>
<script>
import Loader from '../layouts/Loader.vue';

export default {
    data() {
        return {}
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        article() {
            return this.$store.getters.article;
        },
        locale() {
            return this.$store.getters.locale;
        }
    },
    methods: {
        isEmpty(obj) {
            for (var x in obj) {
                return false;
            }
            return true;
        },
        dateLocale(date) {
            if (date) {
                return new Date(date).toLocaleDateString(this.locale);
            }
            return 'no date'
        },
    },
    async mounted() {
        const articleId = this.$route.params.id
        await this.$store.dispatch('fetchArticle', articleId);
    },
    components: {Loader}

}
</script>